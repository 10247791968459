<template>
  <main class="full-page-wrapper" v-if="!requestSent">
    <h1 class="-h2">Create Account</h1>
    <div class="name-container">
      <input
        ref="first_name"
        type="text"
        placeholder="First Name"
        class="-ntr"
        v-model.trim="first_name"
        @blur="
          [
            validateString(first_name, 'first_name'),
            fillBg('first_name', first_name),
          ]
        "
      />
      <input
        ref="last_name"
        type="text"
        placeholder="Last Name"
        class="-ntr"
        v-model.trim="last_name"
        @blur="
          [
            validateString(last_name, 'last_name'),
            fillBg('last_name', last_name),
          ]
        "
      />
    </div>
    <input
      ref="email"
      type="text"
      placeholder="Email"
      class="-ntr"
      v-model.trim="email"
      @blur="
        [emailValidation(['email'], ['errMsgEmail']), fillBg('email', email)]
      "
    />
    <p class="-xstb error-msg" ref="errMsgEmail">{{ errMsgEmail }}</p>
    <p class="-xstb" v-show="existingEmail" id="errMsgExistingEmail">
      {{ errMsgExistingEmail }}
    </p>
    <input
      ref="password"
      type="password"
      placeholder="Password"
      class="-ntr"
      v-model.trim="password"
      @blur="
        [
          passwordValidation(
            ['password', 'confirmPassword'],
            ['errMsgPassword', 'errMsgConfirmPassword']
          ),
          fillBg('password', password),
        ]
      "
    />
    <p class="-xstb error-msg" ref="errMsgPassword">{{ errMsgPassword }}</p>
    <input
      ref="confirmPassword"
      type="password"
      placeholder="Confirm Password"
      class="-ntr"
      v-model.trim="confirmPassword"
      @blur="
        [
          passwordValidation(
            ['password', 'confirmPassword'],
            ['errMsgPassword', 'errMsgConfirmPassword']
          ),
          fillBg('confirmPassword', confirmPassword),
        ]
      "
    />
    <p class="-xstb error-msg" ref="errMsgConfirmPassword">
      {{ errMsgPassword }}
    </p>

    <div class="terms-container">
      <input
        type="checkbox"
        id="terms-checkbox"
        style="cursor: pointer"
        @click="toggleTerms"
      />
      <p style="user-select: none; text-align: left" class="-stb">
        I agree to the
        <span class="-ntb" id="terms">Terms & Conditions</span> and
        <span class="-ntb" id="policy">Privacy Policy</span>
      </p>
    </div>
    <p class="-xstb error-msg" ref="errMsgTerms" id="errMsgTerms">
      {{ errMsgTerms }}
    </p>

    <div class="btn-register" @click="handleClick()">
      <p class="-ntb">Register</p>
    </div>
    <p class="-stb" style="align-self: center">
      Already have an account? Log in
      <router-link :to="{ name: 'Login' }" id="here">here</router-link>
    </p>
  </main>
  <main v-else class="confirm-container"><VerifyAccount /></main>
</template>

<script>
import {
  validateEmail,
  validateBasicType,
  validateLength,
} from "../../../../helpers/validator";
import VerifyAccount from "./VerifyAccount.vue";
import {
  addErrInputText,
  removeErrInputText,
} from "../../../../helpers/styleToggler";
import { register } from "../../../../services/requests/post/auth";
export default {
  components: {
    VerifyAccount,
  },
  data() {
    return {
      requestSent: false,
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      confirmPassword: "",
      //validations
      emailValidated: false,
      passwordValidated: false,
      termsValidated: false,
      existingEmail: false,
      // error messages
      errMsgEmail: "Please enter a valid email.",
      errMsgExistingEmail: "Email already registered.",
      errMsgPassword: "Teste Pass",
      errMsgConfirmPassword: "Teste Confirm Pass",
      errMsgTerms:
        "Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy",
    };
  },
  methods: {
    async handleClick() {
      const validateTerms = this.termsValidation();
      const validation =
        this.emailValidated && this.passwordValidated ? true : false;

      if (!validation || !validateTerms) return;

      const data = {
        name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        password: this.password,
        password_confirmation: this.confirmPassword,
      };

      const res = await register(data);
      console.log(res);
      switch (true) {
        case res.status == 200:
          this.requestSent = true;
          break;
        case res.status == 422:
          this.existingEmail = true;
          this.$refs.email.classList.add("error-input");
          this.$refs.email.classList.remove("fill");
          break;

        default:
          break;
      }
    },

    termsValidation() {
      if (this.termsValidated) {
        return true;
      } else {
        this.$refs.errMsgTerms.style.display = "flex";
        return false;
      }
    },
    emailValidation(inputRef, msgRef) {
      if (this.existingEmail) this.existingEmail = false;
      this.emailValidated = validateEmail(this.email);
      this.toggleErrorEffects(this.emailValidated, inputRef, msgRef);
    },

    passwordValidation(inputRef, msgRef) {
      if (this.password.length > 0 && this.confirmPassword.length > 0) {
        const passValidationLength = validateLength(this.password, 8, 24);

        this.passwordValidated = validateBasicType(
          this.password,
          this.confirmPassword
        );

        if (!passValidationLength) {
          this.errMsgPassword =
            "Your password must be between 8 and 24 characters long!";
          this.toggleErrorEffects(passValidationLength, inputRef, msgRef);
        } else if (!this.passwordValidated) {
          this.errMsgPassword = "Your passwords must be the same!";
          this.toggleErrorEffects(this.passwordValidated, inputRef, msgRef);
        } else {
          this.toggleErrorEffects(this.passwordValidated, inputRef, msgRef);
        }
      }
    },
    toggleErrorEffects(refState, inputRefArray, msgRefArray) {
      if (!refState) {
        for (let i = 0; i < inputRefArray.length; i++) {
          this.$refs[inputRefArray[i]].classList.add("error-input");
          this.$refs[msgRefArray[i]].style.display = "flex";
        }
      } else {
        for (let i = 0; i < inputRefArray.length; i++) {
          this.$refs[inputRefArray[i]].classList.remove("error-input");
          this.$refs[msgRefArray[i]].style.display = "none";
        }
      }
    },
    fillBg(ref, element) {
      if (element.length > 0) {
        this.$refs[ref].classList.add("fill");
      } else {
        this.$refs[ref].classList.remove("fill");
      }
    },
    validateString(text, ref) {
      const domEl = this.$refs[ref];
      !text.length ? addErrInputText(domEl) : removeErrInputText(domEl);
    },
    toggleTerms() {
      this.termsValidated = !this.termsValidated;

      !this.termsValidated
        ? (this.$refs.errMsgTerms.style.display = "flex")
        : (this.$refs.errMsgTerms.style.display = "none");
    },
  },
};
</script>

<style scoped>
.full-page-wrapper {
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  padding-inline: 10%;
  padding-block: 16px;
}
.-h2 {
  font-family: "Montserrat-Black";
  letter-spacing: 1px;
}
input[type="text"],
input[type="password"] {
  padding: 14px;
  width: 100%;
  border: 1px solid var(--gray5);
  border-radius: 4px;
  letter-spacing: 1px;
}
.name-container {
  display: flex;
  width: 100%;
  gap: 50px;
  justify-content: space-between;
}
.terms-container {
  display: flex;
  gap: 16px;
  align-self: center;
  color: var(--gray3);
  border: none !important;
  box-shadow: none !important;
}
.terms-container span {
  color: var(--black3);
}
input[type="checkbox"] {
  width: 20px;
  height: 20px;
  border-radius: 4px;
}
.btn-register {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  background: var(--secondary);
  width: 300px;
  padding: 16px;
  border-radius: 8px;
  user-select: none;
  cursor: pointer;
  gap: 8px;
  align-self: center;
  font-family: "Montserrat-ExtraBold";
  letter-spacing: 1px;
  transition: 200ms ease;
}
.btn-register p {
  font-size: 32px;
}
#here {
  color: var(--highlight);
  filter: brightness(70%);
  user-select: none;
  cursor: pointer;
  text-decoration: underline;
  transition: 200ms ease;
}
.fill {
  background: rgba(0, 76, 255, 0.15);
}

.confirm-container {
  position: relative;
  height: 100%;
  display: flex;
  width: 100%;
  justify-content: center;
}
/*=== EFFECTS ===*/
input[type="text"]:focus,
input[type="password"]:focus {
  border-color: var(--focus1);
  box-shadow: 0 0 1px 3px var(--focus2);
  outline: none;
}
.error-msg {
  margin-top: -18px;
}
.btn-register:hover {
  transform: scale(1.1);
  filter: brightness(110%);
}
#here:hover {
  display: inline-block;
  transform: translateY(-2px);
}
#terms:hover {
  color: var(--highlight);
  filter: brightness(70%);
  user-select: none;
  cursor: pointer;
  text-decoration: underline;
  transition: 200ms ease;
}
#policy:hover {
  color: var(--highlight);
  filter: brightness(70%);
  user-select: none;
  cursor: pointer;
  text-decoration: underline;
  transition: 200ms ease;
}
#errMsgTerms {
  align-self: center;
}
#errMsgExistingEmail {
  color: salmon;
}
@media screen and (max-width: 1024px) {
  .full-page-wrapper > p {
    width: 95%;
  }
}
</style>