export async function resendVerify() {
  try {
    const endpoint = "/email/resend";
    const res = await apiClient.get(endpoint);

    return res.status;
  } catch (err) {
    console.log("Err on AuthJs");
  }
}
